@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import url('https://fonts.cdnfonts.com/css/sunday-burger');

@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&display=swap');

body{
  font-family: 'Fuzzy Bubbles', cursive;
}
 
.burger {
  font-family: 'Sunday Burger', sans-serif;
                                                
}

/* Your custom CSS file */
.object-fit-cover {
  @apply object-contain;
}
